body {
  font-family: Roboto, "Helvetica Neue", Arial, "Noto Sans", system-ui;
  color: #fafafa;
  background-image: url(../images/bg.jpg);
  background-position: right;
  background-size: cover;
}

@media (min-width: 768px) {
  .main-content {
    margin: 3rem 0;
    padding: 1rem;
  }

  .watermark {
    right: -5rem;
  }
}

@media (min-width: 1200px) {
  .main-content {
    margin: 5rem 0;
    padding: 3rem;
  }

  .card-text {
    padding: 0rem 2.1rem;
  }

  .watermark {
    right: -10rem;
  }
}


.main-content {
  padding: 1rem;
}

.header-bullet {
  position: relative;
  width: 8.66px;
  height: 15px;
  background-color: #fff;
  margin: 4.33px 0;
}

.header-bullet:before,
.header-bullet:after {
  content: "";
  position: absolute;
  width: 0;
  border-top: 7.5px solid transparent;
  border-bottom: 7.5px solid transparent;
}

.header-bullet:before {
  left: 100%;
  border-left: 4.33px solid #fff;
}

.header-bullet:after {
  right: 100%;
  width: 0;
  border-right: 4.33px solid #fff;
}

.header-separator {
  position: absolute;
  width: 1.5rem;
  height: 1px;
  left: 12px;
  top: 34px;
  background-color: #fff;
}

.header-text {
  position: relative;
  text-align: center;
  line-height: 40px;
  padding: 0.5rem 1rem;
  margin-left: 2.1rem;
}

.header-text:before,
.header-text:after {
  position: absolute;
  content: '';
  width: 100%;
  left: 0px;
  height: 18px;
}

.header-text:before {
  transform: perspective(18px) rotateX(8deg);
}

.header-text:after {
  top: 28px;
  transform: perspective(18px) rotateX(-8deg);
}

.header-text:before,
.header-text:after {
  border: 1px solid #fff;
}

.header-text:before {
  border-bottom: none;
  /* to prevent the border-line showing up in the middle of the shape */
}

.header-text:after {
  border-top: none;
  /* to prevent the border-line showing up in the middle of the shape */
}

.header-text .step-1 {
  font-weight: bolder;
}

.header-text .step-2 {
  font-weight: lighter;
}

.card {
  border: none;
  border-radius: 0;
  background-color: transparent;
}

.card-header {
  display: flex;
  padding-left: 0;
  align-items: baseline;
  background-color: transparent;
  border: none;
}

.card-header:first-child {
  border-radius: 0;
}

.card-body {
  padding: 0;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
}

.tp-input,
.tp-input:focus {
  color: #fff;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #fff;
  background-color: transparent;
  box-shadow: none;
  padding: 0.1rem 0.1rem;
}

.tp-txtarea,
.tp-txtarea:focus {
  border-radius: 1rem;
  background: transparent;
  color: #fff;
}

.tp-txtarea:disabled {
  color: #fff;
}

.input-group-text {
  border-radius: 0;
  max-width: 3rem;
  background-color: transparent;
  margin-right: 1rem;
  justify-content: center;
  color: #fff;
}

.form-check-input {
  border-radius: 0.25em !important;
  margin-right: 10px;
}

.form-check-input,
.form-check-input:checked {
  border-color: #fff;
  background-color: transparent;
}

.watermark {
  position: absolute;
  bottom: 2rem;
  width: 100%;
  height: 100%;
  background-image: url(../images/watermark.png);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
  opacity: 0.1;
}

.banner {
  height: 100%;
  min-height: 5rem;
  width: 100%;
  margin-top: auto;
  margin-bottom: 1rem;
  background-image: url(../images/banner.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
}

#plan_url {
  margin-top: 2rem;
  color: #5a5858;
}

.form-select {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
  background-size: 20px 16px;
  border: none;
  background-color: transparent;
  color: #fff;
  text-align: right;
}

.form-select:focus {
  box-shadow: none;
}

.form-select:hover {
  cursor: pointer;
}

select option {
  color: black;
}

.tp-howitem .input-group {
  align-items: center;
}

.tp-notes i {
  margin-left: 0.5rem !important;
}

.tp-note-viewonly {
  margin-left: 4rem;
}